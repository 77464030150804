<template>
  <span>
    <el-button size="mini" @click="start(id,pid)">{{id ? $t('button.edit') : $t('button.add')}}</el-button>
    <el-dialog
      :visible.sync="showModal"
      append-to-body
      :title="id ? $t('button.edit') : $t('button.add')"
      width="500px">
      <div v-loading="getIng">
        <el-form ref="form" :model="form" size="medium" :rules="rules" label-width="auto">
          <el-form-item :label="$t('resource.type')" prop="type">
            <el-select v-model="form.type" :placeholder="$ts('placeholder.select', 'resource.type')" style="width: 100%">
              <el-option
                v-for="item in types"
                :key="item.value"
                :label="$t('resource.' + item.label)"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('resource.name')" prop="name">
            <el-input v-model="form.name" :placeholder="$ts('placeholder.input', 'resource.name')"/>
          </el-form-item>
          <el-form-item :label="$t('resource.mark')" prop="mark">
            <el-input v-model="form.mark" :placeholder="$ts('placeholder.input', 'resource.mark')"/>
          </el-form-item>
          <el-form-item :label="$t('resource.enabled')" prop="enabled">
            <el-radio v-model="form.enabled" :label="1">{{$t('resource.enabled')}}</el-radio>
            <el-radio v-model="form.enabled" :label="2">{{$t('resource.disabled')}}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('resource.sort')" prop="sort">
            <el-input v-model="form.sort" :placeholder="$ts('placeholder.input', 'resource.sort')"/>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
          <el-button type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
          <el-button @click="showModal = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from "@/api/resource";
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "saveResourceBtn",
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    pid: {
      type: [String, Number],
      default: ''
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        type: '',
        enabled: 1,
        sort: 0,
      },
      rules: {
        type: [
          {required: true, message: this.$ts('placeholder.select', 'resource.type'), trigger: 'blur'},
        ],
        name: [
          {required: true, message: this.$ts('placeholder.input', 'resource.name'), trigger: 'blur'},
        ],
        mark: [
          {required: true, message: this.$ts('placeholder.input', 'resource.mark'), trigger: 'blur'},
        ],
        enabled: [
          {required: true, message: this.$ts('placeholder.input', 'resource.enabled'), trigger: 'blur'},
        ],
        sort: [
          {required: true, message: this.$ts('placeholder.input', 'resource.sort'), trigger: 'blur'},
        ],
      },
      getIng: false,
      setIng: false,
      types: [
        {value: 'dir', label: 'dir'},
        {value: 'menu', label: 'menu'},
        {value: 'btn', label: 'btn'},
        {value: 'func', label: 'func'},
        {value: 'link', label: 'link'},
      ]
    }
  },
  methods: {
    start(id, pid) {
      this.showModal = true
      this.getIng = false
      this.setIng = false
      
      
      if (id) {
        this.form = {}
        this.getInfo(id)
      } else {
        this.form = {
          type: 'menu',
          enabled: 1,
          sort: 0,
          pid: pid
        }
      }
    },
    getInfo(id) {
      this.getIng = true
      detail({id}).then(res => {
        this.form = res.data
      }).finally(() => {
        this.getIng = false
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          
          this.setIng = true
          save(this.form).then(() => {
            this.showModal = false
            showSuccessMsg(this.$t('message.title'), this.$t('message.operateSuccess'))
            this.$emit('getList')
          }).finally(() => {
            this.setIng = false
          })
          
        }
      })
    },
  }
}
</script>
<style scoped>
</style>