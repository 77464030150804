<template>
  <span>
    <el-button size="mini" type="danger" @click="start(ids)" :loading="loading">{{$t('button.delete')}}</el-button>
  </span>
</template>

<script>
import {del} from '@/api/resource'
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "deleteResourceBtn",
  props: {
    ids: {
      type: [String, Number, Array],
      default: '',
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    start(ids) {
      this.loading = true
      del(ids).then(() => {
        showSuccessMsg(this.$t('message.title'), this.$t('message.deleteSuccess'))
        this.$emit('getList', false)
      }).finally(() => {
        setTimeout(()=>{
          this.loading = false
        },500)
      })
    }
  }
}
</script>

<style scoped>

</style>