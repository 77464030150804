<template>
  <div>
    <div style="margin-bottom: 15px">
      <el-button type="success" size="medium" @click="append({id:0})" icon="el-icon-plus">{{$t('button.add')}}</el-button>
      <el-button type="primary" size="medium" @click="onGet" :loading="getIng" icon="el-icon-refresh">{{$t('button.refresh')}}</el-button>
      <el-button type="primary" size="medium" @click="onSet" :loading="setIng">{{$t('button.saveSortAfterDrag')}}</el-button>
    </div>
    <div style="height: 0;overflow: hidden">
      <save-resource-btn ref="saveResourceBtn" @getList="onGet"/>
    </div>
    <div class="tree-box">
      <el-tree
        v-loading="getIng"
        ref="tree"
        :data="tree"
        default-expand-all
        highlight-current
        :draggable="true"
        @node-drop="handleDrop"
        :indent="20"
        node-key="id"
        :props="{
          children: 'children',
          label: 'name',
        }">
        <div class="treeOne custom-tree-node" slot-scope="{ node, data }">
          <each-resource :node="node" :data="data" @onGet="onGet"/>
        </div>
      </el-tree>
    </div>
  </div>
</template>
<script>
import {listToTree, showSuccessMsg, treeToList} from '@/utils/func'
import {list as getList, updateMore} from "@/api/resource";
import EachResource from "@/views/system/resource/EachResource";
import SaveResourceBtn from "@/views/system/resource/btns/saveResourceBtn";

export default {
  name: "ResourceList",
  components: {SaveResourceBtn, EachResource},
  data() {
    return {
      tree: [],
      getIng: false,
      setIng: false
    }
  },
  mounted() {
    this.onGet()
  },
  methods: {
    onGet() {
      this.tree = [];

      this.getIng = true
      getList({pageNo: 1, pageSize: 1000000}).then(res => {
        let list = res.data.list
        this.tree = listToTree(list)
      }).finally(() => {
        setTimeout(() => {
          this.getIng = false
        }, 500)
      })
    },
    onSet() {
      let list = treeToList(listToTree(treeToList(this.tree, 0)))
      list = list.map((v) => {
        return {
          id: v.id,
          pid: v.pid,
          sort: v.sort
        }
      })
      this.setIng = true
      updateMore(list).then(() => {
        showSuccessMsg(this.$t('message.title'), this.$t('message.saveSuccess'))
        this.onGet()
      }).finally(() => {
        setTimeout(() => {
          this.setIng = false
        }, 500)
      })

    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.$nextTick(() => {

      });
    },

    append(data) {
      this.$refs.saveResourceBtn.start(0, data.id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/style/var.scss";

.treeOne {
  clear: both;
  width: 100%;
  padding-left: 5px;
}

.tree-box {
  background-color: #fff;
  padding: 20px;
  margin: 5px 0;
  border-radius: 8px;
  box-shadow: $--box-shadow-base;
}
</style>